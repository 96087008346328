import { Email } from "./email.model";
import { EngagementType } from "./engagement.model";
import { Phone } from "./phone.model";

// Participant status
export enum eParticipantStatus {
	ACTIVE = "ACTIVE",
	PROSPECTIVE = "PROSPECTIVE",
	INACTIVE = "INACTIVE",
	DNC = "DNC",
}
export type ParticipantStatus = (typeof eParticipantStatus)[keyof typeof eParticipantStatus];

export type PreferredCoachingMethod = "PHONE" | "EMAIL" | "CARE_MESSAGE";
export enum ePreferredCoachingMethod {
	PHONE = "PHONE",
	EMAIL = "EMAIL",
	CARE_MESSAGE = "CARE_MESSAGE",
}

export enum PrincipalMethod {
	PHONE = "PHONE",
	EMAIL = "EMAIL",
}

export enum UnenrollmentReason {
	NOT_INTERESTED = "NOT_INTERESTED",
	NO_TIME = "NO_TIME",
	SELF_CARE = "SELF_CARE",
	NO_NEED = "NO_NEED",
	ENROLLED_FAMILY = "ENROLLED_FAMILY",
	ENROLLED_OTHER = "ENROLLED_OTHER",
	NOT_ELIGIBLE = "NOT_ELIGIBLE",
	OTHER = "OTHER",
}

export enum TestingFrequency {
	DAILY = "DAILY",
	WEEKLY = "WEEKLY",
	MONTHLY = "MONTHLY",
	YEARLY = "YEARLY",
	PRN = "PRN",
	EOD = "EOD",
}

export enum Gender {
	M = "M",
	F = "F",
	OTHER = "OTHER",
}

export enum PreferredPronouns {
	HE_HIM = "HE_HIM",
	SHE_HER = "SHE_HER",
	THEY_THEM = "THEY_THEM",
	PREFER_NOT = "PREFER_NOT",
	OTHER = "OTHER",
}

export enum PreferredLanguage {
	ENGLISH = "ENGLISH",
	SPANISH = "SPANISH",
	HAITIAN_CREOLE = "HAITIAN_CREOLE",
	MARSHALLESE = "MARSHALLESE",
	FRENCH = "FRENCH",
}

export enum Timezone {
	US_Eastern = "US/Eastern",
	US_Central = "US/Central",
	US_Mountain = "US/Mountain",
	US_Pacific = "US/Pacific",
	US_Alaska = "US/Alaska",
	US_Hawaii = "US/Hawaii",
	US_Arizona = "US/Arizona",
	NA = "N/A",
}

export enum TimeOfDay {
	"8AM_10AM" = "8AM_10AM",
	"10AM_NOON" = "10AM_NOON",
	"NOON_2PM" = "NOON_2PM",
	"2PM_4PM" = "2PM_4PM",
	"4PM_6PM" = "4PM_6PM",
	"6PM_8PM" = "6PM_8PM",
}

export enum DaysOfWeek {
	"MO" = "MO",
	"TU" = "TU",
	"WE" = "WE",
	"TH" = "TH",
	"FR" = "FR",
	"SA" = "SA",
	"SU" = "SU",
}

export interface Participant {
	id: number;
	firstName: string;
	lastName: string;
	kannactId: string;
	timezone: Timezone;
	phone: string;
	status: ParticipantStatus;
	gender: string;
	institution: string;
	language: string;
	dob: string; // Date Of Birth
	contactPurpose: string;
	participantEmail: string;
	coachId: number;
	nextEngagement: Date;
	preferredContactType: EngagementType;
	preferredCoachingMethod: PreferredCoachingMethod;
	isWholeHealth: boolean;
	overview: string;
	schedulingLink: string;
	subscriptionCenterLink: string;
	isTest: boolean;
}

export interface ParticipantDevice {
	deviceName: string;
	participantId: number;
	serialNumber: string;
	status: string;
	deviceType: string;
}

export interface ParticipantPrincipal {
	username: string;
	method: PrincipalMethod;
}

export interface ParticipantProfile {
	id: number;
	firstName: string;
	lastName: string;
	kannactId: string;
	principal: ParticipantPrincipal[] | null;
	timezone: string | null;
	phones: Phone[];
	status: ParticipantStatus;
	gender: Gender | null;
	institutionId: number;
	institution: string;
	language: PreferredLanguage | null;
	dob: string; // Date Of Birth
	contactPurpose: string;
	emails: Email[];
	pronouns: PreferredPronouns | null;
	coachId: number;
	billingProviderId: number | null;
	nextEngagement: Date;
	preferredContactType: EngagementType;
	preferredCoachingMethod: PreferredCoachingMethod | null;
	isWholeHealth: boolean;
	overview: string;
	schedulingLink: string;
	subscriptionCenterLink: string;
	unsubscribeLink: string;
	intakeSurveyLink: string;
	insuranceSurveyLink: string;
	hipaaReleaseSurveyLink: string;
	emEncounterSurveyLink: string;
	standaloneConsentSurveyLink: string;
	intakeNoConsentSurveyLink: string;
	isCovered: boolean;
	unsubscriptions: string[];
	contactId: number | null;
	claimsElegible: boolean;
	enrollmentDate: string; // Date
	referrerEmail: string;
	referrerName: string;
	isTest: boolean;
	unenrollmentReason: UnenrollmentReason | string | null;
	unenrollmentOtherReason: string | null;
	shippingAddressId: number | null;
	shippingAddressAddressLine1: string | null;
	shippingAddressAddressLine2: string | null;
	shippingAddressCity: string | null;
	shippingAddressState: string | null;
	shippingAddressPostalCode: string | null;
	shippingAddressCountry: string | null;
	latestTermsOfServiceAccepted: string;
	consentedSms: boolean;
	preferredName: string | null;
	timeOfDay: TimeOfDay | string | null;
	daysOfWeek: string | null; // DaysOfWeek separated by comas
	glucometerFrequencyReading: number | null;
	glucometerFrequencyPeriod: TestingFrequency | null;
	bpFrequencyReading: number | null;
	bpFrequencyPeriod: TestingFrequency | null;
	devices: ParticipantDevice[];
	brandName: string;
	brandLogo: string;
}
