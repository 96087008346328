import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UiTable from "../../../components/table/Table.component";
import { PageProps } from "../../../models/routing.model";
import { HelperService } from "../../../services/helpers/helper.service";
import {
	TablePagination,
	ITablePagination,
} from "../../../models/components/table/table-pagination.model";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import { ParticipantRow } from "../../../models/pages/participants-row.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { ITableHeaderRefresh } from "../../../models/components/table/table-refresh.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableFilter,
	ITableHeaderFilter,
} from "../../../models/components/table/table-filter.model";
import { ProviderTwilio } from "../../../models/entities/provider.model";
import { List, ListOption } from "../../../models/misc.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { BackendPagination } from "../../../models/pagination.model";
import {
	eParticipantStatus,
	ePreferredCoachingMethod,
	Participant,
} from "../../../models/entities/participant.model";
import { FilterService } from "../../../services/filter/filter.service";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { ITableHeaderSearch } from "../../../models/components/table/table-search.model";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";
import UiButton from "../../../components/button/Button";
import { AuthRoles, Roles } from "../../../models/roles.model";
import ParticipantsCreateModal from "./ParticipantsCreateModal";
import { ProtectedComponent } from "../../../services/routing/protected-component.service";

const ParticipantsDashboard = (props: PageProps) => {
	const { t } = useTranslation("common");

	/* Table */

	// Columns
	const setColumns = (): TableColumn[] => {
		const columns: TableColumn[] = [
			{
				field: "kannactId",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.kannactId",
				template: TableColumnTemplate.KANNACT_ID,
			},
			{
				field: "name",
				sortingField: "firstName,lastName",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.name",
				sortable: true,
			},
			{
				field: "participantEmail",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.participantEmail",
				sortable: true,
			},
			{
				field: "status",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.status",
				template: TableColumnTemplate.TAG,
			},
			{
				field: "contactPurpose",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.contactPurpose",
				template: TableColumnTemplate.TRANSLATE,

				sortable: true,
			},
			{
				field: "timezone",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.TIMEZONE",
				template: TableColumnTemplate.TRANSLATE,
				sortable: true,
			},
			{
				field: "phone",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.phone",
				template: TableColumnTemplate.PHONE_CALL,
				style: {
					minWidth: "220px",
				},
			},
		];

		if (HelperService.isAdminViewMode()) {
			const column = {
				field: "coachId",
				title: "PARTICIPANTS.DASHBOARD.TABLE.COLUMNS.coachId",
				template: TableColumnTemplate.COACH,
				sortable: true,
			};
			columns.splice(3, 0, column);
		}

		return columns;
	};

	const columns: TableColumn[] = setColumns();

	const [rows, setRows] = useState<ParticipantRow[]>();

	// Filters
	const statusFilter: ITableFilter = {
		field: "status",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "PARTICIPANTS.DASHBOARD.FILTERS.STATUS_TITLE",
				options: [
					{
						id: eParticipantStatus.PROSPECTIVE,
						label: "ENUMS.PARTICIPANT_STATUS.PROSPECTIVE",
					},
					{
						id: eParticipantStatus.ACTIVE,
						label: "ENUMS.PARTICIPANT_STATUS.ACTIVE",
					},
					{ id: eParticipantStatus.DNC, label: "ENUMS.PARTICIPANT_STATUS.DNC" },
					{
						id: eParticipantStatus.INACTIVE,
						label: "ENUMS.PARTICIPANT_STATUS.INACTIVE",
					},
					{
						id: "null",
						label: "ENUMS.PARTICIPANT_STATUS.NOT_COMPUTED",
					},
				],
			},
		},
		value: ["ACTIVE"],
	};
	const coachingFilter: ITableFilter = {
		field: "preferredCoachingMethod",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "PARTICIPANTS.DASHBOARD.FILTERS.COACHING_TITLE",
				options: [
					{
						id: ePreferredCoachingMethod.PHONE,
						label: "ENUMS.COACHING_METHOD.PHONE",
					},
					{
						id: ePreferredCoachingMethod.CARE_MESSAGE,
						label: "ENUMS.COACHING_METHOD.CARE_MESSAGE",
					},
					{
						id: ePreferredCoachingMethod.EMAIL,
						label: "ENUMS.COACHING_METHOD.EMAIL",
					},
				],
			},
		},
		value: [],
	};
	const isTestFilter: ITableFilter = {
		field: "isTest",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			columnClass: "col-12 col-md-2 col-lg-2 col-xl-1",
			props: {
				label: "PARTICIPANTS.DASHBOARD.FILTERS.IS_TEST_TITLE",
				options: [
					{
						id: "true",
						label: "ENUMS.YES",
					},
					{
						id: "false",
						label: "ENUMS.NO",
					},
				],
			},
		},
		value: ["false"],
	};
	const coachFilter: ITableFilter = {
		field: "coachId",
		type: TableFilterType.SELECT_MULTIPLE,
		value: [`${getSessionStorageUser()?.id}`],
	};
	const [filters, setFilters] = useState<ITableHeaderFilter | undefined>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-dashboard"]) ?? {
			filters: [statusFilter, coachingFilter, isTestFilter],
		}
	);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(
			StoredCriteriaService.KEYS["participants-dashboard"]
		) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: undefined,
			sortOrder: undefined,
			search: undefined,
			filters: filters?.filters,
		}
	);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Refresh
	const refresh: ITableHeaderRefresh = {
		fn: () => {
			getData(pagination);
		},
	};

	// Search
	const tableSearch: ITableHeaderSearch = {
		title: "PARTICIPANTS.DASHBOARD.SEARCH_PLACEHOLDER",
		value:
			StoredCriteriaService.getPagination(
				StoredCriteriaService.KEYS["participants-dashboard"]
			)?.search ?? undefined,
		fn: (value: string | null | undefined) => {
			// Update pagination state
			const newPagination: TablePagination = {
				...pagination,
				search: value?.trim(),
				page: 0,
				first: 0,
			};
			setPagination(newPagination);

			// Update stored list pagination/filters
			StoredCriteriaService.setPagination(
				StoredCriteriaService.KEYS["participants-dashboard"],
				newPagination
			);

			// Update data
			getData(newPagination);
			getCount(newPagination);
		},
	};

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);

		// Get filters
		if (
			!StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-dashboard"])
				?.filters
		)
			getFilters();
	}, []);

	// Get data: participants
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		// Update pagination object
		const updatedEvent: ITablePagination = {
			...event,
			sortField: HelperService.compoundSortField(columns, event),
		};

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode()) {
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) ?? []), coachFilter];
		}

		await EndpointsService.dataRetriever
			.getParticipants({
				config: {
					params: new BackendPagination(updatedEvent),
				},
			})
			.then((response: Participant[]) => {
				if (response?.length > 0) setRows(response.map((item) => new ParticipantRow(item)));
				else
					setMessage({
						severity: FieldMessageSeverity.INFO,
						label: messages.empty,
					});
			})
			.catch((error: AxiosError) =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		// Update pagination object
		const updatedEvent: ITablePagination = { ...event };

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode()) {
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) ?? []), coachFilter];
		}

		const queryParams = new BackendPagination(updatedEvent);
		await EndpointsService.dataRetriever
			.getParticipantsCount({
				config: {
					params: {
						search: queryParams.search,
						filters: queryParams.filters,
					},
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Participants: Error getting the count");
			});
	};

	// Pagination (page changed)
	const onPagination = async (event: TablePagination): Promise<void> => {
		// When selected page changes, update the pagination state
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);

		// Update stored criteria
		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["participants-dashboard"],
			newPagination
		);

		// Get data
		getData(newPagination);
	};

	/**
	 * FILTERS
	 */

	// Filters: get options (call a backend endpoint to retreive the coaches list)
	const getFilters = async (): Promise<void> => {
		// Filter: Reach Out status
		const roStatus: List = [];
		await EndpointsService.dataRetriever
			.getRoStatus()
			.then((response: string[]) => {
				// If there is data --> Map it
				if (response?.length > 0) {
					response.forEach((item) => {
						roStatus.push(
							new ListOption({
								id: item,
								label: item.includes("RO_")
									? t(`ENUMS.CONTACT_PURPOSE.RO_`, {
											attempt: item.split("RO_")[1],
										})
									: `ENUMS.CONTACT_PURPOSE.${item}`,
							})
						);
					});
				}
			})
			.catch((error: AxiosError) => {
				console.error("Participants - Couldn't get the RO status list");
			});

		// Filter: Institution
		const institutions: List = [];
		await EndpointsService.dataRetriever
			.getInstitutions()
			.then((response) => {
				if (response?.length > 0) {
					response.forEach((x) => {
						institutions.push(new ListOption({ id: x, label: x }));
					});
				}
			})
			.catch((error: AxiosError) => {
				console.error("Participants - Couldn't get the institutions list");
			});

		// Filter: Coaches
		const providersList: List = [];
		if (HelperService.isAdminViewMode()) {
			await EndpointsService.dataRetriever
				.getProvidersTwilio()
				.then((response: ProviderTwilio[]) => {
					// If there is data --> Map it
					if (response?.length > 0) {
						response.forEach((item) => {
							providersList.push(
								new ListOption({
									id: item.coachId,
									label: `${item.firstName} ${item.lastName}`,
								})
							);
						});
					}
				})
				.catch((error: AxiosError) => {
					console.error("Participants - Couldn't get the providers list");
				});
		}

		mapFilters(providersList, roStatus, institutions);
	};

	// Filters: submit
	const submitFilters = (value: TableFilterFormData): void => {
		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);
		setFilters({ ...filters, filters: newFilters });

		// Update pagination
		const newPagination: TablePagination = {
			...pagination,
			filters: newFilters,
			page: 0,
			first: 0,
		};
		setPagination(newPagination);

		// Update stored criteria
		StoredCriteriaService.set(
			StoredCriteriaService.KEYS["participants-dashboard"],
			newPagination,
			{
				...filters,
				filters: newFilters,
			}
		);

		// Update table message
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.filter,
		});

		// Get data
		getData(newPagination);
		getCount(newPagination);
	};

	// Filters: map
	const mapFilters = (providers: List, roStatus: List, institutions: List): void => {
		// Map filter: roStatus
		let tmpRoFilter: ITableFilter[] = [];
		if (roStatus.length > 0) {
			tmpRoFilter = [
				{
					field: "contactPurpose",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						columnClass: "col-12 col-md-3 col-lg-3",
						props: {
							label: "PARTICIPANTS.DASHBOARD.FILTERS.RO_TITLE",
							options: roStatus,
							filter: true,
						},
					},
					value: [],
				},
			];
		}

		// Map filter: institutions
		let tmpInstitutionsFilter: ITableFilter[] = [];
		if (institutions.length > 0 && !Roles.isProvider()) {
			tmpInstitutionsFilter = [
				{
					field: "institution",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						columnClass: "col-12 col-md-3 col-lg-3",
						props: {
							label: "PARTICIPANTS.DASHBOARD.FILTERS.INSTITUTION_TITLE",
							options: institutions,
							filter: true,
						},
					},
					value: [],
				},
			];
		}

		// Map filter: coach
		let tmpCoachFilter: ITableFilter[] = [];
		if (providers.length > 0) {
			tmpCoachFilter = [
				{
					field: "coachId",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						columnClass: "col-12 col-md-3 col-lg-3",
						props: {
							label: "PARTICIPANTS.DASHBOARD.FILTERS.COACH_TITLE",
							options: providers,
							filter: true,
						},
					},
					value: [],
				},
			];
		}

		const updatedFilters: ITableFilter[] = [
			...tmpCoachFilter,
			...tmpRoFilter,
			...tmpInstitutionsFilter,
			...(filters?.filters as ITableFilter[]),
		];

		// Update filters
		setFilters({ ...filters, filters: updatedFilters });

		// Update stored criteria
		StoredCriteriaService.setFilters(StoredCriteriaService.KEYS["participants-dashboard"], {
			...filters,
			filters: updatedFilters,
		});
	};

	/**
	 * MODAL: CREATE
	 */

	const [modalIsVisible, setModalIsVisible] = useState(false);

	return (
		<>
			<UiTable
				useAsCard={true}
				dataKey="kannactId"
				title={
					HelperService.isAdminViewMode()
						? "PARTICIPANTS.DASHBOARD.TABLE.TITLE_ADMIN"
						: "PARTICIPANTS.DASHBOARD.TABLE.TITLE_COACH"
				}
				customHeader={
					<ProtectedComponent
						allowedRoles={[
							AuthRoles.ADMIN,
							AuthRoles.COACH_COORDINATOR,
							AuthRoles.PROVIDER,
						]}
					>
						<UiButton
							type="button"
							className="p-button-rounded p-button-sm"
							icon={"pi pi-plus"}
							label="PARTICIPANTS.DASHBOARD.BUTTON_CREATE"
							onClick={() => setModalIsVisible(true)}
						/>
					</ProtectedComponent>
				}
				columns={columns}
				value={rows}
				message={message}
				isServerPaginated={true}
				pagination={pagination}
				paginationFn={onPagination}
				totalRecords={count}
				refresh={refresh}
				search={tableSearch}
				filter={filters}
				filterFn={submitFilters}
			/>

			{/* Modal: create participant */}
			{modalIsVisible && (
				<ParticipantsCreateModal
					isVisible={modalIsVisible}
					onClose={() => setModalIsVisible(false)}
				/>
			)}
		</>
	);
};
export default ParticipantsDashboard;
