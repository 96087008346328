import { HelperService } from "../../services/helpers/helper.service";
import {
	Gender,
	ParticipantStatus,
	PreferredCoachingMethod,
	PreferredLanguage,
	PreferredPronouns,
	TestingFrequency,
	UnenrollmentReason,
	ParticipantProfile,
} from "./../entities/participant.model";

export enum DetailsMode {
	READ = "READ",
	UPDATE = "UPDATE",
}

export class PtGeneralInfoForm {
	firstName: string;
	lastName: string;
	preferredName: string | null;
	gender: Gender | null;
	pronouns: PreferredPronouns | null;
	dob: Date;
	language: PreferredLanguage | null;

	constructor(params: ParticipantProfile) {
		this.firstName = params.firstName;
		this.lastName = params.lastName;
		this.preferredName = params.preferredName;
		this.gender = params.gender;
		this.pronouns = params.pronouns;
		this.dob = new Date(params.dob);
		this.language = params.language;
	}
}

export class PtKannactInfoForm {
	isWholeHealth: boolean;
	institutionId: number;
	institution: string;
	coachId: number | null;
	billingProviderId: number | null;
	status: ParticipantStatus;
	unenrollmentReason: UnenrollmentReason | string | null;
	unenrollmentOtherReason: string | null;
	glucometerFrequencyReading: number | null;
	glucometerFrequencyPeriod: TestingFrequency | null;
	bpFrequencyReading: number | null;
	bpFrequencyPeriod: TestingFrequency | null;
	emailPrincipal: string | null;
	phonePrincipal: string | null;
	referrerEmail: string | null;
	referrerName: string | null;
	isTest: boolean;
	isCovered: boolean;
	claimsElegible: boolean;

	constructor(params: ParticipantProfile) {
		this.emailPrincipal = HelperService.getPrincipalEmail(params);
		this.phonePrincipal = HelperService.getPrincipalPhone(params, false);
		this.isWholeHealth = params.isWholeHealth;
		this.institutionId = params.institutionId;
		this.institution = params.institution;
		this.coachId = params.coachId;
		this.status = params.status;
		this.unenrollmentReason = params.unenrollmentReason;
		this.unenrollmentOtherReason = params.unenrollmentOtherReason;
		this.glucometerFrequencyReading = params.glucometerFrequencyReading;
		this.glucometerFrequencyPeriod = params.glucometerFrequencyPeriod;
		this.bpFrequencyReading = params.bpFrequencyReading;
		this.bpFrequencyPeriod = params.bpFrequencyPeriod;
		this.isTest = params.isTest;
		this.isCovered = params.isCovered;
		this.claimsElegible = params.claimsElegible;
		this.referrerEmail = params.referrerEmail;
		this.referrerName = params.referrerName;
		this.billingProviderId = params.billingProviderId;
	}
}

export class PtContactForm {
	phone1: number | null;
	phone2: number | null;
	phone3: number | null;
	primaryPhone: number | null;
	email1: string | null;
	email2: string | null;
	primaryEmail: string | null;
	preferredCoachingMethod: PreferredCoachingMethod | null;
	timezone: string | null;
	timeOfDay: string | null;
	daysOfWeek: string[];
	shippingAddressAddressLine1: string | null;
	shippingAddressAddressLine2: string | null;
	shippingAddressCity: string | null;
	shippingAddressCountry: string | null;
	shippingAddressState: string | null;
	shippingAddressPostalCode: string | null;

	constructor(params: ParticipantProfile) {
		this.phone1 = params.phones?.length ? Number(params.phones[0].phoneNumber) : null;
		this.phone2 = params.phones?.length > 1 ? Number(params.phones[1].phoneNumber) : null;
		this.phone3 = params.phones?.length > 2 ? Number(params.phones[2].phoneNumber) : null;
		this.primaryPhone = params.phones?.length
			? Number(params.phones.find((p) => p.isPrimary)!.phoneNumber)
			: null;
		this.email1 = params.emails?.length ? params.emails[0].emailAddress : null;
		this.email2 = params.emails?.length > 1 ? params.emails[1].emailAddress : null;
		this.primaryEmail = params.emails?.length
			? params.emails.find((e) => e.isPrimary)!.emailAddress
			: null;
		this.preferredCoachingMethod = params.preferredCoachingMethod;
		this.timezone = params.timezone;
		this.timeOfDay = params.timeOfDay;
		this.daysOfWeek = params.daysOfWeek ? params.daysOfWeek.split(",") : [];
		this.shippingAddressAddressLine1 = params.shippingAddressAddressLine1;
		this.shippingAddressAddressLine2 = params.shippingAddressAddressLine2;
		this.shippingAddressCity = params.shippingAddressCity;
		this.shippingAddressCountry = params.shippingAddressCountry;
		this.shippingAddressState = params.shippingAddressState;
		this.shippingAddressPostalCode = params.shippingAddressPostalCode;
	}
}

export class PtSubscriptionsForm {
	programUpdatesAndNewsletters: boolean;
	deviceOutOfRangeAlerts: boolean;
	remindersMedicalDevices: boolean;
	remindersScheduleCoachingCalls: boolean;
	programChanges: boolean;
	consentedSms: boolean;

	constructor(params: ParticipantProfile) {
		this.programUpdatesAndNewsletters = true;
		this.deviceOutOfRangeAlerts = true;
		this.remindersMedicalDevices = true;
		this.remindersScheduleCoachingCalls = true;
		this.programChanges = true;
		this.consentedSms = params.consentedSms;

		const unsubscriptions = params.unsubscriptions || [];
		if (unsubscriptions.includes("UNSUBSCRIBE_CE_UNSUBSCRIBE_TO_ALL_EMAILS_ABOVE")) {
			this.programUpdatesAndNewsletters = false;
			this.deviceOutOfRangeAlerts = false;
			this.remindersMedicalDevices = false;
			this.remindersScheduleCoachingCalls = false;
			this.programChanges = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_REMINDERS_TO_SCHEDULE_COACH_CALLS")) {
			this.remindersScheduleCoachingCalls = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_DEVICE_OUT_OF_RANGE_ALERTS")) {
			this.deviceOutOfRangeAlerts = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_PROGRAM_UPDATES_NEWSLETTERS")) {
			this.programUpdatesAndNewsletters = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_REMINDERS_TO_USE_MEDICAL_DEVICES")) {
			this.remindersMedicalDevices = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_ENROLLMENT_MARKETING")) {
			this.programChanges = false;
		}
	}
}
