import {
	PanelListMessageSeverity,
	UiPanelListProps,
	PanelList,
} from "../../../../models/components/panel-list.model";
import UiPanelList from "../../../../components/panel-list/PanelList";
import { BiometricsFormatter } from "../../../../services/biometrics/biometrics-formatter.service";
import { BiometricType } from "../../../../models/entities/biometrics.model";
import { BiometricsLatest } from "../../../../models/endpoints/biometrics-endpoint.model";
import { useBiometrics } from "../../../../hooks/useBiometrics";
import { useBiometricGoals } from "../../../../hooks/useBiometricGoals";
import { useTranslation } from "react-i18next";
import { Goal } from "../../../../models/entities/goal.model";
import { GoalsService } from "../../../../services/goals/goals.service";
import { BiometricsHelper } from "../../../../services/biometrics/biometrics-helper.service";
import { useNavigate } from "react-router-dom";

const ParticipantDashboardBiometrics = ({ participantId }: { participantId: number }) => {
	const navigate = useNavigate();

	const biometrics = useBiometrics({ participantId });
	const biometricGoals = useBiometricGoals({ participantId });

	const isLoading = biometrics.isLoading;
	const isError = biometrics.isError;

	const latestBiometrics = BiometricsHelper.getLatestValues(biometrics.data ?? new Map());

	return (
		<QuickViewPanel
			isLoading={isLoading}
			isError={isError}
			latestBiometrics={latestBiometrics}
			goals={biometricGoals.data ?? []}
			openModal={() =>
				navigate(`/participant/${participantId}/biometrics/list`, {
					replace: true,
				})
			}
		/>
	);
};

const QuickViewPanel = (props: {
	isLoading: boolean;
	isError: boolean;
	latestBiometrics: BiometricsLatest;
	goals: Goal[];
	openModal: () => void;
}) => {
	const { t } = useTranslation("common");

	const message = props.isLoading
		? {
				severity: PanelListMessageSeverity.LOADING,
				message: "PARTICIPANT.DASHBOARD.BIOMETRICS.MESSAGES.LOADING",
			}
		: props.isError
			? {
					severity: PanelListMessageSeverity.ERROR,
					message: "PARTICIPANT.DASHBOARD.BIOMETRICS.MESSAGES.ERROR",
				}
			: {
					severity: PanelListMessageSeverity.INFO,
					message: "PARTICIPANT.DASHBOARD.BIOMETRICS.MESSAGES.NO_DATA",
				};

	const panelProps = new UiPanelListProps({
		title: "PARTICIPANT.DASHBOARD.BIOMETRICS.QUICK_VIEW.PANEL_TITLE",
		collapsible: true,
		hasAction: true,
		actionLabel: "PARTICIPANT.DASHBOARD.BIOMETRICS.QUICK_VIEW.ACTION_LABEL",
		actionFn: props.openModal,
		messages: {
			noData: "PARTICIPANT.DASHBOARD.BIOMETRICS.MESSAGES.NO_DATA",
			loading: "PARTICIPANT.DASHBOARD.BIOMETRICS.MESSAGES.LOADING",
			error: "PARTICIPANT.DASHBOARD.BIOMETRICS.MESSAGES.ERROR",
		},
		message,
		list: [],
	});

	const list =
		props.isLoading || props.isError
			? []
			: getPanelList(
					props.latestBiometrics,
					props.goals,
					t("ENUMS.MEAL_STATE", { returnObjects: true })
				);

	return <UiPanelList props={{ ...panelProps, list }} />;
};

const getPanelList = (
	latestBiometrics: BiometricsLatest,
	biometricGoals: Goal[],
	translatedMealStates: { [x: string]: string }
) => {
	const a1cGoals = biometricGoals.find((goal) => goal.goalName.startsWith("A1C"));
	const bpGoals = biometricGoals.find((goal) => goal.goalName.startsWith("BPS"));

	const glucoseMealState = BiometricsHelper.getGlucoseMealState(latestBiometrics.glucose);
	const formattedGlucoseMealState =
		glucoseMealState !== "UNKNOWN" ? ` ${translatedMealStates[glucoseMealState]}` : "";

	const list: PanelList[] = [
		{
			// Hemoglobin A1C
			label: `ENUMS.BIOMETRICS.hemoglobinA1c`,
			value: BiometricsFormatter.formatHemoglobinA1c(
				latestBiometrics.hemoglobinA1c?.entries.find(
					(x) => x.subType === BiometricType.hemoglobin_a1c
				)?.value ?? null
			),
			goal: GoalsService.showGoal(a1cGoals) ? a1cGoals : undefined,
		},
		{
			// Blood pressure
			label: `ENUMS.BIOMETRICS.bloodPressure`,
			value: BiometricsFormatter.formatBloodPressure(
				latestBiometrics.bloodPressure?.entries.find(
					(x) => x.subType === BiometricType.blood_pressure_systolic
				)?.value ?? null,
				latestBiometrics.bloodPressure?.entries.find(
					(x) => x.subType === BiometricType.blood_pressure_diastolic
				)?.value ?? null
			),
			goal: GoalsService.showGoal(bpGoals) ? bpGoals : undefined,
		},
		{
			// Glucose
			label: `ENUMS.BIOMETRICS.glucose`,
			value:
				BiometricsFormatter.formatGlucose(
					latestBiometrics.glucose?.entries.find(
						(x) => x.subType === BiometricType.glucose
					)?.value ?? null
				) + formattedGlucoseMealState,
		},
		{
			// Weight
			label: `ENUMS.BIOMETRICS.weight`,
			value: BiometricsFormatter.formatWeight(
				latestBiometrics.weight?.entries.find((x) => x.subType === BiometricType.weight)
					?.value ?? null
			),
		},
		{
			// Cholesterol
			label: `ENUMS.BIOMETRICS.cholesterol`,
			value: BiometricsFormatter.formatCholesterol(
				latestBiometrics.cholesterol?.entries.find(
					(x) => x.subType === BiometricType.cholesterol_hdl
				)?.value ?? null,
				latestBiometrics.cholesterol?.entries.find(
					(x) => x.subType === BiometricType.cholesterol_ldl
				)?.value ?? null,
				latestBiometrics.cholesterol?.entries.find(
					(x) => x.subType === BiometricType.cholesterol_total
				)?.value ?? null
			),
		},
		{
			// Triglycerides
			label: `ENUMS.BIOMETRICS.triglycerides`,
			value: BiometricsFormatter.formatTriglycerides(
				latestBiometrics.triglycerides?.entries.find(
					(x) => x.subType === BiometricType.triglycerides
				)?.value ?? null
			),
		},
		{
			// Kidney GFR
			label: `ENUMS.BIOMETRICS.kidneyGfr`,
			value: BiometricsFormatter.formatKidneyGfr(
				latestBiometrics.kidneyGfr?.entries.find(
					(x) => x.subType === BiometricType.kidney_gfr
				)?.value ?? null
			),
		},
	];

	return list;
};

export default ParticipantDashboardBiometrics;
